<template>
  <div>
    <b-table
      v-if="callLogs.length"
      ref="refRelatedCallLogsTable"
      class="position-relative has_padding event_list min-height-150 shadow-table"
      :items="callLogs"
      responsive
      :fields="tableColumns"
      primary-key="_id"
      :sort-by.sync="sortBy"
      show-empty
      no-sort-reset
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      :per-page="perPage"
      :current-page="currentPage"
    >
      <!-- Column: Role -->
      <template #cell(stringID)="data">
        <div class="">
          <b-button
            v-if="canViewThisAction('show', 'CallLog')"
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            variant="link"
            class="weight-600 align-middle detail-view-id"
            :to="{ name: 'appointment-call-logs-show', params: { id: data.item._id } }"
          >
            {{ data.item.stringID }}
          </b-button>
          <b-button
            v-else
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            variant="none"
            class="text-bold-black align-middle detail-view-id not-button"
          >
            {{ data.item.stringID }}
          </b-button>
        </div>
      </template>

      <template #cell(customerContact)="data">
        <div class="">
          <span class="">{{ data.item.customerContact }}</span>
        </div>
      </template>

      <template #cell(remarks)="data">
        <div class="">
          <span v-if="data.item.remarks.length > 85">{{ data.item.remarks.substr(0, 100) + '...' }}</span>
          <span v-else>{{ data.item.remarks || '-' }}</span>
        </div>
      </template>

      <template #cell(createdAt)="data">
        <div class="text-nowrap">
          <span class="">{{ dateFormatWithTime(data.item.createdAt) }}</span>
        </div>
      </template>

      <template #cell(agentName)="data">
        <div class="">
          <b-button
            v-if="canViewThisAction('show', 'Agent') && data.item.agent"
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            variant="link"
            class="weight-600 align-middle detail-view-id"
            :to="{ name: 'agency-agents-show', params: { id: data.item.agent } }"
          >
            {{ data.item.agentName }}
          </b-button>
          <span
            v-else
          >
            {{ data.item.agentName || '-' }}
          </span>
        </div>
      </template>

      <!-- Column: Actions -->
      <template
        v-if="canViewThisAction('show', 'CallLog') || canViewThisAction('update', 'CallLog')"
        #cell(actions)="data"
      >
        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >

          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>

          <b-dropdown-item
            v-if="canViewThisAction('show', 'CallLog')"
            :to="{ name: 'appointment-call-logs-show', params: { id: data.item._id } }"
          >
            <feather-icon icon="EyeIcon" />
            <span class="align-middle ml-50">View Call Log</span>
          </b-dropdown-item>

          <b-dropdown-item
            v-if="canViewThisAction('update', 'CallLog')"
            :to="{ name: 'appointment-call-logs-edit', params: { id: data.item._id } }"
          >
            <feather-icon icon="EditIcon" />
            <span class="align-middle ml-50">Edit Call Log</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
      <template #bottom-row="">
        <td
          colspan="10"
        >
          <b-row>
            <b-col
              md="2"
              sm="2"
              class="my-25"
            >
              <b-form-group
                class="mb-0"
              >
                <label class="d-inline-block text-sm-left mr-50 weight-400 fs-14">Per page</label>
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                  class="w-50"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="10"
              sm="10"
              class="my-25"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                align="right"
                prev-text="Prev"
                next-text="Next"
              />
            </b-col>
          </b-row>
        </td>
      </template>
    </b-table>
    <div
      v-else
      class="empty_block"
    >
      <h4>No call log found.</h4>
    </div>
  </div>
</template>

<script>
import {
  BTable, BButton, VBTooltip, BDropdown, BDropdownItem, BPagination, BRow, BCol, BFormGroup, BFormSelect,
} from 'bootstrap-vue'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import { useUtils as useAclUtils } from '@/libs/acl/custom'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BTable,
    BButton,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    callLogs: {
      type: [Array, null],
      default: () => [],
    },
  },
  data() {
    return {
      user: store.state.auth.userData,
      render: false,
      sortBy: 'stringID',
      sortDesc: false,
      isSortDirDesc: true,
      tableItems: [],
      currentPage: 1,
      totalRows: this.callLogs.length,
      perPage: 10,
      pageOptions: [10, 15, 20],
      tableColumns: [
        {
          key: 'stringID',
          label: 'ID',
          sortable: true,
        },
        // {
        //   key: 'customerName',
        //   label: 'Customer Name',
        //   sortable: true,
        // },
        // {
        //   key: 'customerContact',
        //   label: 'Customer contact',
        //   sortable: true,
        //   thStyle: {
        //     width: '11%',
        //   },
        // },
        {
          key: 'typeOfCall.name',
          label: 'Type of call',
          sortable: true,
        },
        {
          key: 'natureOfCall',
          label: 'Nature of call',
          sortable: true,
        },
        // {
        //   key: 'remarks',
        //   label: 'Details & Remarks',
        //   sortable: false,
        //   thStyle: {
        //     width: '20%',
        //   },
        // },
        {
          key: 'agentName',
          label: 'Agent Name',
          sortable: true,
        },
        {
          key: 'createdAt',
          label: 'created date',
          sortable: true,
        },
        {
          key: 'dutyOfficer.name',
          label: 'created by',
          sortable: true,
        },
        { key: 'actions' },
      ],
    }
  },

  methods: {
    canViewThisAction,
  },
}
</script>
